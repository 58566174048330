<template>
	<component
		:is="validation ? 'validation-provider' : 'fragment'"
		class="TypedFieldWrap"
		:name="field.key"
		:rules="(field.required ? 'required|' : '') + (field.validator || '')"
		ref="provaida"
		:id="uuid"
	>
		<template #default="validationContext">
			<component
				:is="clearable && field.filter == 'option' ? 'b-input-group' : 'fragment'"
				style="flex-wrap: nowrap"
			>
				<b-form-checkbox
					v-if="field.filter == 'boolean' && !field.triple"
					:class="`TypedField custom-control-${variant || 'primary'} pt-1`"
					style="min-height: 1.1em; align-items: center; padding-bottom: 0"
					inline
					:checked="!!value"
					@input="
						data => {
							update(data)
							confirm(data)
						}
					"
					:size="size"
					:disabled="disabled !== undefined ? disabled : field.disabled"
					><template v-if="!inline">{{ field.label }}</template></b-form-checkbox
				>
				<div
					:class="{
						TypedField: true,
						triple: true,
						disabled: disabled !== undefined ? disabled : field.disabled,
					}"
					v-else-if="field.filter == 'boolean' && field.triple"
				>
					<b-button-group>
						<b-button
							v-for="(btn, index) in [
								{
									iconName: 'CheckIcon',
									variant: 'success',
									value: 1,
								},
								{
									iconName: 'MinusIcon',
									variant: 'secondary',
									value: undefined,
								},
								{
									iconName: 'XIcon',
									variant: 'danger',
									value: 0,
								},
							]"
							:key="index"
							@click="
								() => {
									update(btn.value)
									confirm(btn.value)
								}
							"
							:class="'btn-icon' + (value == btn.value ? ' active' : '')"
							:variant="'flat-' + btn.variant"
							size="sm"
						>
							<feather-icon :icon="btn.iconName" size="18" style="margin: -2px 0px -3px" />
						</b-button>
					</b-button-group>
					<span>{{ field.label }}</span>
				</div>

				<b-media v-else-if="field.filter == 'image'" no-body>
					<b-media-aside @click="$refs.refInputEl.click()" style="cursor: pointer">
						<b-avatar ref="previewEl" rounded :src="imageData || value" size="120" />
						<!--/ avatar -->
					</b-media-aside>

					<b-media-body class="mt-4 ml-2">
						<b-button variant="success" size="sm" class="ml-2" @click="$refs.refInputEl.click()">
							Загрузить
						</b-button>
						<input
							ref="refInputEl"
							type="file"
							class="d-none"
							@change="event => inputImageRenderer(event, field)"
						/>
					</b-media-body>
				</b-media>
				<b-media v-else-if="field.filter == 'file'" no-body>
					<b-media-aside @click="$refs.refFileEl.click()" style="cursor: pointer">
						<b-avatar rounded size="60" variant="light-primary">
							<feather-icon icon="FileIcon" size="16" />
						</b-avatar>
						<!--/ avatar -->
					</b-media-aside>

					<b-media-body class="pt-1">
						<b-button v-if="!value" variant="outline-success" size="sm" @click="$refs.refFileEl.click()">
							Загрузить
						</b-button>
						<span v-if="value">
							{{ value.name }}
						</span>
						<input
							ref="refFileEl"
							type="file"
							class="d-none"
							@change="
								e => {
									update(e.target.files[0])
									confirm(e.target.files[0])
									$refs.provaida.validate()
								}
							"
						/>
					</b-media-body>
				</b-media>
				<editable-table
					v-else-if="field.filter.value && !inline"
					class="TypedField"
					:fields="field.filter.value"
					:data="value"
					@update:data="
						data => {
							update(data)
							confirm(data)
						}
					"
				>
				</editable-table>
				<!-- <row-picker
					:api="field.filterOptions"
					v-else-if="field.filter == 'modelPicker'"
					:fields="field.filterOptions.fields"
					:init="value"
					:nullable="true"
					label="Выберите причину отсутствия..."
					@input="
						selected => {
							update(selected)
							confirm(selected)
							$refs.provaida.syncValue(selected)
						}
					"
					size="lg"
				>
					<template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
						<slot :name="slotName" v-bind="scope" />
					</template>
				</row-picker> -->
				<b-form-input
					v-else-if="field.filter == 'string'"
					class="TypedField"
					:variant="variant"
					:state="
						validationContext.errors
							? !validationContext.dirty
								? undefined
								: validationContext.valid
							: undefined
					"
					:placeholder="field.label"
					trim
					:value="value"
					@input="update"
					@keyup.enter="confirm"
					:formatter="formatters[field.formatter] || noFormat"
					:size="size"
					:disabled="disabled !== undefined ? disabled : field.disabled"
				/>
				<b-form-input
					v-else-if="field.filter == 'password'"
					class="TypedField"
					:variant="variant"
					:state="
						validationContext.errors
							? !validationContext.dirty
								? undefined
								: validationContext.valid
							: undefined
					"
					:placeholder="field.label"
					trim
					:value="value"
					@input="update"
					@keyup.enter="confirm"
					:formatter="formatters[field.formatter] || noFormat"
					:size="size"
					:disabled="disabled !== undefined ? disabled : field.disabled"
					type="password"
				/>
				<b-form-input
					v-else-if="field.filter == 'number'"
					class="TypedField"
					:variant="variant"
					:state="
						validationContext.errors
							? !validationContext.dirty
								? undefined
								: validationContext.valid
							: undefined
					"
					:placeholder="field.label"
					trim
					:value="value"
					@input="update"
					@keyup.enter="confirm"
					:formatter="formatters[field.formatter] || noFormat"
					:size="size"
					:disabled="disabled !== undefined ? disabled : field.disabled"
				/>
				<!-- <date-time
					v-else-if="field.filter == 'datetime'"
					class="TypedField"
					:variant="variant"
					:state="
						validationContext.errors
							? !validationContext.dirty
								? undefined
								: validationContext.valid
							: undefined
					"
					:placeholder="field.label"
					trim
					:value="value"
					@input="update"
					@confirm="confirm"
					:formatter="formatters[field.formatter] || noFormat"
					:size="size"
					:disabled="disabled !== undefined ? disabled : field.disabled"
				/> -->
				<!-- :max="
					new Date(Date.now() + 24 * 60 * 60 * 1000 - 1)
						.toISOString()
						.split('T')[0]
				" -->
				<!-- <b-form-datepicker
				v-else-if="field.filter == 'date'"
				class="TypedField"
				:variant="variant"
				:state="
					validationContext.errors
						? !validationContext.dirty
							? undefined
							: validationContext.valid
						: undefined
				"
				:placeholder="field.label"
				locale="ru"
				:value="value"
				@input="
					(data) => {
						update(data);
						confirm(data);
					}
				"
				:size="size"
				:disabled="disabled !== undefined ? disabled : field.disabled"
			/> -->
				<b-input-group
					style="flex-wrap: nowrap"
					class="TypedField"
					v-else-if="field.filter == 'date'"
					:size="size"
					:disabled="disabled !== undefined ? disabled : field.disabled"
				>
					<Datepicker
						:placeholder="field.label"
						:typeable="!(disabled !== undefined ? disabled : field.disabled)"
						:disabled="disabled !== undefined ? disabled : field.disabled"
						:input-class="`form-control form-control-${size}`"
						:variant="variant"
						:language="ru"
						:value="value"
						ref="datepick"
						format="yyyy-MM-dd"
						monday-first
						:disabled-dates="{
							to: new Date(min),
							from: new Date(max),
						}"
						@selected="
							data => {
								if (!data) return
								update(data.toISOString().split('T')[0])
								confirm(data.toISOString().split('T')[0])
							}
						"
					/>
					<!-- <b-form-input
					:variant="variant"
					:state="
						validationContext.errors
							? !validationContext.dirty
								? undefined
								: validationContext.valid
							: undefined
					"
					:value="value"
					:placeholder="field.label"
					:formatter="formatters[field.formatter] || noFormat"
					type="text"
					@input="update"
				></b-form-input> -->
					<b-input-group-append>
						<b-button
							class="btn-icon pl-1 pr-1"
							@click="$refs.datepick.showCalendar()"
							variant="outline-primary"
							><feather-icon icon="CalendarIcon" size="16"
						/></b-button>
					</b-input-group-append>
				</b-input-group>

				<b-input-group
					style="flex-wrap: nowrap"
					class="TypedField"
					v-else-if="field.filter == 'dateDuty'"
					:size="size"
					:disabled="disabled !== undefined ? disabled : field.disabled"
				>
					<!-- <Datepicker
						:placeholder="field.label"
						:typeable="!(disabled !== undefined ? disabled : field.disabled)"
						:disabled="disabled !== undefined ? disabled : field.disabled"
						:input-class="`form-control form-control-${size}`"
						:variant="variant"
						:language="ru"
						:value="value"
						ref="datepick"
						format="yyyy-MM-dd"
						:disabled-dates="disabledFn || {}"
						monday-first
						@selected="
							data => {
								if (!data) return
								update(data.toISOString().split('T')[0])
								confirm(data.toISOString().split('T')[0])
							}
						"
					/> -->
					<b-input-group-append>
						<b-button
							class="btn-icon pl-1 pr-1"
							@click="$refs.datepick.showCalendar()"
							variant="outline-primary"
							><feather-icon icon="CalendarIcon" size="16"
						/></b-button>
					</b-input-group-append>
				</b-input-group>
				<b-input-group class="TypedField" v-else-if="field.filter == 'time'" :size="size">
					<!-- <vue-clock-picker
						ref="clockpicker"
						required
						:formatter="formatters[field.formatter] || noFormat"
						done-text="Выбрать"
						cancel-text="Отмена"
						:value="(value || '').slice(0, 5)"
						:placeholder="field.label"
						@input="
							$event => {
								update($event + ':00')
								confirm($event + ':00')
							}
						"
						@open="checkStatus"
					>
					</vue-clock-picker> -->
					<b-input-group-append>
						<b-button
							class="btn-icon pl-1 pr-1"
							@click="$refs['clockpicker'].open()"
							variant="outline-primary"
							><feather-icon icon="ClockIcon" size="16"
						/></b-button>
					</b-input-group-append>
				</b-input-group>
				<template v-else-if="field.filter == 'option' && !field.variant">
					<v-select
						class="TypedField"
						:class="{ 'select-size-sm': size == 'sm' }"
						:reduce="
							option => {
								return option[field.idField]
							}
						"
						:options="options"
						:input-id="field.key"
						:getOptionLabel="option => optionLabelList(option, field)"
						:placeholder="field.label"
						:value="value"
						:filterable="field.optionType === 'dynamic'"
						@input="
							data => {
								update(data)
								confirm(data)
								persistOption(data)
							}
						"
						@search="
							(search, loading) => {
								handleSearch(search, field, loading)
							}
						"
						@open="
							() => {
								console.log(value, field)
								handleSearch('', field)
							}
						"
						:appendToBody="true"
						:disabled="disabled !== undefined ? disabled : field.disabled"
						:clearable="false"
					>
						<template slot="no-options"> ... </template>
					</v-select>
					<b-input-group-append is-text v-if="clearable">
						<feather-icon
							icon="XIcon"
							class="cursor-pointer"
							@click="
								() => {
									update(undefined)
									confirm(undefined)
								}
							"
						/>
					</b-input-group-append>
				</template>
				<b-tabs
					v-else-if="field.filter == 'option' && field.variant == 'tabs'"
					class="TypedField"
					:value="+value"
					@input="
						data => {
							update(data)
							confirm(data)
						}
					"
					:disabled="disabled !== undefined ? disabled : field.disabled"
				>
					<b-tab
						:variant="variant"
						v-for="option in field.filterOptions"
						:key="option[field.idField]"
						:title="option[field.valueField]"
					/>
				</b-tabs>
				<div
					v-else-if="field.filter == 'nested' && typeof value == 'object' && value !== null"
					class="TypedField nested"
					:disabled="disabled !== undefined ? disabled : field.disabled"
				>
					<div
						v-for="field in field.filterOptions"
						:key="field.key"
						v-show="field.edit === false ? fresh : true"
					>
						<label
							v-if="field.filter != 'boolean' && field.filter !== true && !field.noLabel && field.label"
							>{{ field.label }}</label
						>
						<b-form-group :name="field.label" :label-for="field.label" v-if="field.filter !== true">
							<typed-field
								v-model="value[field.key]"
								:field="field"
								:validation="true"
								@confirm="
									data => {
										update(data)
										confirm(data)
									}
								"
							/>
						</b-form-group>
					</div>
				</div>
				<template v-else-if="field.filter == 'entity_lookup'">
					<component
						v-if="value"
						:is="field.lookup.widget"
						:value="value"
						@clear="clear"
						:clearable="clearable"
					>
					</component>
				</template>
				<b-form-invalid-feedback v-if="validationContext.errors" style="display: block">
					{{ validationContext.errors[0] }}
				</b-form-invalid-feedback>
			</component>
		</template>
	</component>
</template>

<script>
// import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
// import { ru } from 'vuejs-datepicker/src/locale'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate'
import {
	BFormInput,
	BFormCheckbox,
	BFormDatepicker,
	BFormInvalidFeedback,
	BTab,
	BTabs,
	BInputGroup,
	BInputGroupAppend,
	BFormTimepicker,
	BMedia,
	BMediaAside,
	BAvatar,
	BButtonGroup,
	BButton,
	BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import formatters from '@/libs/format'
import { ref, watch, toRefs, inject, reactive, onMounted, watchEffect, computed } from '@vue/composition-api'
import Fragment from './Fragment.vue'
// import RowPicker from './RowPicker.vue'
// import DateTime from './DateTime.vue'
// import VueClockPicker from '@pencilpix/vue2-clock-picker'
import { format, subDays } from 'date-fns'
// import { calculateIsWorkDay } from '@/components/calculate-work-day.js'

import { v4 as uuidv4 } from 'uuid'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
	props: [
		'field',
		'value',
		'inline',
		'size',
		'validation',
		'variant',
		'params',
		'disabled',
		'max',
		'min',
		'optionMixin',
		'clearable',
	],
	components: {
		// VueClockPicker,
		vSelect,
		BFormInput,
		BFormCheckbox,
		BFormDatepicker,
		BFormTimepicker,
		ValidationProvider,
		BFormInvalidFeedback,
		BInputGroup,
		BInputGroupAppend,
		BTab,
		BTabs,
		EditableTable: () => import('./EditableTable.vue'),
		TypedField: () => import('./TypedField.vue'),
		Fragment,
		BMedia,
		BMediaAside,
		BAvatar,
		BMediaBody,
		// Datepicker,
		// RowPicker,
		// DateTime,
		FeatherIcon,
		BButtonGroup,
		BButton,
	},
	directives: {
		Ripple,
	},
	setup(props, { emit }) {
		const { field, params, value } = toRefs(props)
		const provaida = ref(null)

		const { userData, rangeData } = inject('DataForShift', () => new ExpensiveClass())
		const disabledFn = reactive({
			customPredictor(date) {
				let dateF = format(subDays(date, 1), 'yyyy-MM-dd')
				return calculateIsWorkDay(userData?.value?.shift, dateF, rangeData)
			},
		})
		const imageData = ref('')
		const timePicker = props.value
		const minT = ref('')
		const maxT = ref('')

		const persist = ref(props.optionMixin)

		const options =
			field.value.optionType == 'dynamic' ? ref([props.optionMixin].filter(Boolean)) : field.value.filterOptions
		function update(value) {
			// console.log(value)
			if (field.value.filter == 'boolean' && field.value.numeric && (value === true || value === false)) {
				emit('input', +value)
			} else {
				emit('input', value)
			}
		}
		function confirm(value) {
			if (field.value.filter == 'boolean' && field.value.numeric && (value === true || value === false)) {
				emit('confirm', +value)
			} else {
				emit('confirm', value)
			}
		}

		function persistOption(value) {
			if (typeof value == 'object') {
				persist.value = value
			} else {
				if (!(value || value === 0)) {
					persist.value = null
					return
				}
				const find = options.value?.find(option => {
					return option[field.value.idField] == value
				})
				if (find) persist.value = find
			}
			console.log(persist.value, value)
		}

		async function handleSearch(search, field, loading) {
			if (field.optionType !== 'dynamic') return
			if (loading) loading(true)
			const test =
				search == undefined
					? await field.filterOptions.function(search, params.value)
					: await field.filterOptions(search, params.value)

			options.value = test

			if (props.value || props.value === 0) {
				const alreadyThere = options.value.find(option => {
					return (
						option[field.idField] == props.value || option[field.idField] === props.value?.[field.idField]
					)
				})
				if (!alreadyThere && persist.value) {
					options.value.unshift(persist.value)
				}
			}
			if (loading) loading(false)
		}

		function inputImageRenderer(event) {
			provaida?.value?.setErrors(['Уменьшение...'])
			const file = event.target.files[0]
			if (!file) return provaida?.value?.setErrors(['Нет подходящего файла'])

			const reader = new FileReader()

			reader.addEventListener(
				'load',
				async () => {
					const canvas = document.createElement('canvas')
					canvas.ctx = canvas.getContext('2d', { alpha: false })
					const img = new Image()
					img.onload = async () => {
						// return;
						canvas.width = img.width
						canvas.height = img.height
						canvas.ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
						// let blob = await (new Promise((res, rej) => {
						// 	canvas.toBlob((blob) => {
						// 		res(blob);
						// 	});
						// }));
						// let size = blob.size;
						let blob = canvas.toDataURL('image/jpeg', 0.95)
						let size = blob.length
						while (size > 2 * 1000 * 1000) {
							const diff = Math.sqrt(Math.min(1, 1 / (size / 1000 / 1000))) - 0.07
							canvas.width = canvas.width * diff
							canvas.height = canvas.height * diff
							canvas.ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
							// document.body.appendChild(canvas);
							// blob = await (new Promise((res, rej) => {
							// 	canvas.toBlob((blob) => {
							// 		res(blob);
							// 	});
							// }));
							// size = blob.size;
							blob = canvas.toDataURL('image/jpeg', 0.95)
							size = blob.length
							console.log(size)
						}
						const file = await new Promise((res, rej) => {
							canvas.toBlob(
								blob => {
									res(blob)
								},
								'image/jpeg',
								0.95,
							)
						})
						if (field.value.base64) {
							emit('input', blob)
							emit('confirm', blob)
						} else {
							emit('input', file)
							emit('confirm', file)
						}
						provaida.value.reset()
						imageData.value = blob
					}
					img.src = reader.result
				},
				false,
			)
			reader.readAsDataURL(file)
		}

		const clockpicker = ref(null)
		const uuid = '_' + uuidv4()

		function checkStatus() {
			let el1 = document.querySelector(`.clock-picker__dialog-drop`)

			el1.addEventListener('click', function(event) {
				clockpicker.value?.close()
			})
		}

		// onMounted(async () => {
		// 	calculateNewDays(12,"2023-03-30");
		// });
		const optionLabelList = function(option, field) {
			if (typeof field.valueField == 'function') {
				return option['last_name'] + ' ' + option[field.valueField()]
			} else {
				return option[field.valueField]
			}
		}

		//this part will ensure the object exists in the parent data even if it was null before for the purpose of nested field binding
		const fresh = ref(false)
		if ((typeof value.value !== 'object' || value.value === null) && field.value.filter == 'nested') {
			fresh.value = true
			emit('input', {})
		}

		const clear = () => {
			emit('clear')
		}

		return {
			minT,
			maxT,
			update,
			checkStatus,
			uuid,
			clockpicker,
			confirm,
			options,
			handleSearch,
			formatters,
			noFormat: a => a,
			console,
			inputImageRenderer,
			imageData,
			// ru,
			timePicker,
			disabledFn,
			persistOption,
			optionLabelList,
			fresh,
			clear,
			provaida,
		}
	},
}
</script>

<style scoped>
.nested {
	padding-left: 1em;
	border-left: 2px solid var(--secondary);
}
</style>

<style>
/* @import '@/assets/scss/clock-picker.scss'; */

.vs__dropdown-menu {
	z-index: 9999999999999 !important;
}
.input-group > .TypedField {
	flex-grow: 1;
}
.TypedField.custom-control.custom-control {
	font-weight: normal !important;
}
.TypedField.custom-control label {
	user-select: none;
}
.TypedField.custom-checkbox.custom-control {
	padding-left: 0;
}
.TypedField.custom-checkbox .custom-control-label,
.TypedField.custom-radio .custom-control-label {
	padding-left: 2em;
	position: relative;
}
.TypedField.custom-checkbox .custom-control-label:before {
	left: 0;
}
.TypedField.v-select .vs__open-indicator {
	display: none;
}
.input-group .vdp-datepicker {
	flex-grow: 1;
}
.input-group .vdp-datepicker input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.TypedField.select-size-sm.v-select.vs--single .vs__selected + .vs__search {
	display: none;
}
.TypedField.select-size-sm.v-select.vs--open .vs__selected + .vs__search {
	display: inline-block;
}
.TypedField.select-size-sm.v-select.vs--single .vs__selected {
	padding-top: 2px;
	padding-bottom: 3px;
}

.clock-picker__input-container {
	font-size: 0;
}
.TypedField.b-custom-control-sm.custom-radio .custom-control-label,
.TypedField.b-custom-control-sm.custom-checkbox .custom-control-label {
	padding-left: 1.5em;
}
.statusButtonStyle {
	padding: 5px !important;
	height: 28.5px;
	border-color: #d8d6de !important;
	border-radius: 0px !important;
}

.TypedField.triple {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}
.TypedField.triple span {
	padding: 0 0.5em;
	white-space: nowrap;
}
.TypedField.triple .btn + .btn {
	border-left: 1px solid #d8d6de;
	border-radius: 0;
}
.TypedField.triple .btn-group {
	border: 1px solid #d8d6de;
	border-radius: 0.357rem;
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
}
.vdp-datepicker__calendar .cell.cell {
	border-radius: 0.256rem;
}
.vdp-datepicker__calendar .cell.cell.selected {
	background: #7367f0;
	border: 1px solid #7367f0;
	color: white;
	font-weight: bold;
}
.vdp-datepicker__calendar .cell.cell.selected:hover {
	background: #7367f0;
	border: 1px solid #7367f0;
}
.vdp-datepicker__calendar .cell.cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell.cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell.cell:not(.blank):not(.disabled).year:hover {
	border: 1px solid #7367f0;
}
.vs--disabled > div,
.vs--disabled > div .vs__search {
	background: #efefef !important;
}
</style>
